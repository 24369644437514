.face {
    display: grid;
    grid-template-columns: repeat(3, auto);
}

.up {
    grid-row: 1;
    grid-column: 2;
}

.left {
    grid-row: 2;
    grid-column: 1;
}

.front {
    grid-row: 2;
    grid-column: 2;
}

.right {
    grid-row: 2;
    grid-column: 3;
}

.back {
    grid-row: 2;
    grid-column: 4;
}

.down {
    grid-row: 3;
    grid-column: 2;
}
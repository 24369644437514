.tile {
    border: 2px black solid;
    width: 60px;
    height: 60px;
}

.white {
    background-color: white;
}

.orange {
    background-color: orange;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}

.blue {
    background-color: blue;
}

.yellow {
    background-color: yellow;
}